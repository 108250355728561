<template>
  <div>
    <div class="login-container">
      <!--背景图-->
      <ul class="cb-slideshow">
        <li style="background-image:url('http://resouce.cdzyhd.com/4498fc31-143c-48d9-8014-cc23e6139e9e.png')"></li>
      </ul>
      <div class="title-container" :style="isPhone?'padding-top:50px':''">
        <h3 class="title-img">
          <img :src="courseInfo.headAvatar" alt="">
        </h3>
        <h3 class="title" :style="isPhone?'font-size:25px':''">
          {{ courseInfo.name }}
        </h3>
        <h4 class="sub-title">
        </h4>
      </div>
      <!--输入框-->
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="off"
               label-position="left">

        <el-form-item prop="account">
          <span class="svg-container">
            <i class="el-icon-user"></i>
          </span>
          <el-input
              :style="isPhone?'width:80%':''"
              ref="account"
              onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
              v-model="loginForm.account"
              name="account"
              placeholder="请输入账号"
              type="text"
              tabindex="2"
              @change="onLoginAccountChange"
              autocomplete="off"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="大写键已打开" placement="right" manual>
          <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-lock"></i>
          </span>
            <el-input
                :style="isPhone?'width:80%':''"
                onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="请输入密码"
                name="password"
                tabindex="4"
                autocomplete="off"
                @keyup.native="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <i class="el-icon-view"></i>
            </span>
          </el-form-item>
        </el-tooltip>


        <div class="btn-box">
          <el-button round :loading="loading" type="primary"
                     style="display:block;width:35%;margin:0 auto;margin-bottom:30px;font-size: 17px"
                     @click.native.prevent="handleLogin">
            登 录
          </el-button>
          <el-button round type="text"
                     class="reg-btn" v-if="systemConfig.regFunction==='open'"
                     @click.native.prevent="RegMethods().clickOpenRegBtn()">
            注册
          </el-button>
          <el-button type="text" class="btn-forget" @click="clickForgetPasswordBtn">忘记密码？</el-button>
        </div>

        <div class="system-tips" v-if="isPhone">
          <div class="mobile-bottom-tips flex flex-dr flex-center"
               style="margin-top: 20px;text-align: center">
            <div>技术支持：</div>
            <div>成都智云鸿道信息技术有限公司</div>
            <div>蜀ICP备19034784号-1</div>
            <div>推荐使用Chrome谷歌、Firefox火狐、360极速浏览器、Edge等浏览器访问本系统</div>
          </div>
        </div>
      </el-form>
      <!--底部信息-->
      <div class="bottomTips" v-if="!isPhone">
        <div>技术支持：成都智云鸿道信息技术有限公司</div>
        <div style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">蜀ICP备19034784号-1</div>
        <div>推荐使用Chrome谷歌、Firefox火狐、360极速浏览器、Edge等浏览器访问本系统</div>
      </div>
    </div>
    <!--弹出框-找回密码-->
    <el-dialog
        title="重置密码"
        :visible.sync="findPasswordShow"
        width="400px"
        :close-on-click-modal="false"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form ref="resetForm" :model="resetForm" :rules="resetRules">
          <el-form-item prop="account">
            <el-input placeholder="请输入账号" v-model="resetForm.account" @change="onResetAccountChange"></el-input>
          </el-form-item>
          <!--          <el-form-item prop="email">-->
          <!--            <el-input placeholder="请输入绑定的邮箱" v-model="resetForm.email"-->
          <!--                      @keyup.enter.native="clickResetPasswordBtn"></el-input>-->
          <!--          </el-form-item>-->
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickResetPasswordBtn">重置密码</el-button>
      </div>
    </el-dialog>
    <!--弹出框-完善信息-->
    <el-dialog
        title="首次登录，请完善信息"
        :visible.sync="addInfoShow"
        width="400px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form ref="addInfoForm" :model="addInfoForm" :rules="addInfoRules">
          <el-form-item prop="password1">
            <el-input placeholder="请输入6-12位的新密码" v-model="addInfoForm.password1"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input placeholder="请再次输入新密码" v-model="addInfoForm.password2" type="password"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input placeholder="请输入邮箱地址，便于找回密码" v-model="addInfoForm.email"
                      @keyup.enter.native="clickAddInfoBtn"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex flex-start">
              <el-input placeholder="请输入验证码" maxlength="6" v-model="addInfoForm.code"
              ></el-input>
              <el-button style="margin-left: 10px" :disabled="addInfoForm.send" type="text" size="small"
                         @click="clickSendEmailCodeBtn">发送验证码至邮箱
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="clickAddInfoBtn">确 认</el-button>
      </div>
    </el-dialog>
    <!--弹出框-用户注册-->
    <el-dialog
        title="用户注册"
        :visible.sync="regInfo.dialog"
        width="400px"
        center
        :close-on-click-modal="false"
    >
      <div class="dialog-container">
        <div class="tips" style="color: #999;font-size: 12px;margin-bottom: 15px;text-align: center">
          请使用您的学号或工号注册
        </div>
        <el-form ref="regForm" :model="regInfo.info" :rules="regInfo.rules">
          <el-form-item prop="account">
            <el-input placeholder="请输入账号" v-model="regInfo.info.account"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password1">
            <el-input placeholder="请输入6-12位的密码" v-model="regInfo.info.password1"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input placeholder="请再次输入密码" v-model="regInfo.info.password2" type="password"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input placeholder="请输入真实姓名" v-model="regInfo.info.name"
                      onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sex">
            <el-select v-model="regInfo.info.sex" placeholder="请选择性别" style="width: 100%">
              <el-option value="女" label="女"></el-option>
              <el-option value="男" label="男"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" v-model="regInfo.info.phone"
            ></el-input>
          </el-form-item>
          <el-form-item prop="identity">
            <el-input placeholder="请输入您的身份（学生、教师、职员等等）" v-model="regInfo.info.identity"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input placeholder="请输入邮箱地址，便于找回密码" v-model="regInfo.info.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex flex-start">
              <el-input placeholder="请输入验证码" maxlength="6" v-model="regInfo.info.emailCode"
              ></el-input>
              <el-button style="margin-left: 10px" :disabled="regInfo.send" type="text" size="small"
                         @click="RegMethods().clickSendEmailCodeBtn()">发送验证码至邮箱
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="RegMethods().clickRegBtn()">注 册</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {getQuery, isMobile, regCheck, shareStart, validateMaxLength} from "@/utils/common";
import {msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";
import {UserModel} from "../model/UserModel";
import {checkPhone} from "../utils/common";
import {ConfigModel} from "../model/ConfigModel";
import store from "../store";


export default {
  name: 'Login',
  components: {},
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
      systemConfig: state => state.systemConfig,
      courseInfo: state => state.courseInfo,
    })
  },
  directives: {
    elDragDialog
  },
  data() {
    // 校检账号
    const validateAccount = (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      callback()
    }
    // 邮箱输入检测
    const validateEmail = (rule, value, callback) => {
      if (!regCheck("email", value)) {
        callback(new Error("邮箱格式错误"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请输入6-12位密码"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword2 = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请再次输入6-12位密码"))
      }
      if (this.addInfoForm.password1) {
        if (this.addInfoForm.password1 !== value) {
          callback(new Error("两次密码输入不一致"))
        }
      }
      callback()
    }
    // 邮箱验证码检测
    const validateCode = (rule, value, callback) => {
      let reg = /^[0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('请输入验证码'))
      }
      if (value.length !== 6 || isNaN(Math.ceil(value))) {
        callback(new Error("请输入6位数字验证码"))
      }
      callback()
    }
    // 校检姓名
    const validateName = (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      // 特殊字符检测
      let regEn = /[`!#$%^&*()@_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('不支持特殊符号'));
      }
      // 仅支持中英文
      regEn = /[a-zA-Z]+/
      regCn = /[\u4e00-\u9fa5]+/g
      if (regEn.test(value) || regCn.test(value)) {
        // 判断是否有数字
        let regNumber = /\d/
        if (regNumber.test(value)) {
          callback(new Error('不能输入数字'));
        }
      } else {
        callback(new Error('仅支持中英文'));
      }
      callback()
    }
    // 校检注册电话号码
    const validateRegPhoneNumber = async (rule, value, callback) => {
      if (!checkPhone(value)) {
        callback(new Error('手机号格式不正确'))
      }
      // 判断账号是否重复
      let repeatCheck = await UserModel.checkUserInfoRepeat("phone", value);
      if (repeatCheck) {
        callback(new Error('该账号已被绑定，请修改后再试'));
      }
      callback()
    }
    // 校检注册账号
    const validateRegAccount = async (rule, value, callback, maxLength, propName) => {
      if (!(value === 0 || value)) {
        callback(new Error('请输入' + propName))
        return
      }
      if (value.length < 4) {
        callback(new Error('最少输入' + 4 + '个字，当前已输入' + value.length + "个字"))
      }
      if (value.length > maxLength) {
        callback(new Error('最多输入' + maxLength + '个字，当前已输入' + value.length + "个字"))
      }
      // 特殊字符检测
      let regEn = /[`!#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('仅支持数字、字母、部分符号'));
      }
      // 中文检测
      regCn = /[\u4e00-\u9fa5]+/g
      if (regCn.test(value)) {
        callback(new Error('仅支持数字、字母、部分符号'));
      }
      // 判断账号是否重复
      let repeatCheck = await UserModel.checkUserInfoRepeat("account", value);
      if (repeatCheck) {
        callback(new Error('该账号已被注册，请修改后再试'));
      }
      callback()
    }
    // 注册密码输入检测
    const validateRegPassword2 = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请再次输入6-12位密码"))
      }
      if (this.regInfo.info.password1) {
        if (this.regInfo.info.password1 !== value) {
          callback(new Error("两次密码输入不一致"))
        }
      }
      callback()
    }
    // 注册邮箱输入检测
    const validateRegEmail = async (rule, value, callback) => {
      if (!regCheck("email", value)) {
        callback(new Error("邮箱格式错误"))
      }
      // 判断邮箱是否重复
      let repeatCheck = await UserModel.checkUserInfoRepeat("email", value);
      if (repeatCheck) {
        callback(new Error('该邮箱已被绑定，请修改后再试'));
      }
      callback()
    }
    return {
      window:window,
      loginForm: {
        account: '',
        password: '',
        schoolName: '',
      },
      loginRules: {
        schoolName: [{required: true, trigger: 'blur', validator: (r, v, c) => validateAccount(r, v, c, 30, '学校名称')}],
        account: [{required: true, trigger: 'blur', validator: (r, v, c) => validateAccount(r, v, c, 30, '账号')}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}],
        teacherid: [{required: true, trigger: 'blur', message: '请选择教师'}]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      // 登录页配置
      // 找回密码、重置密码
      resetForm: {
        account: "",
      },
      resetRules: {
        account: [{required: true, trigger: 'blur', message: '请输入账号'}],
      },
      findPasswordShow: false,
      // 完善信息
      addInfoForm: {
        password1: "",
        password2: "",
        send: false,
        email: ""
      },
      addInfoRules: {
        password1: {required: true, trigger: 'blur', validator: validatePassword},
        password2: {required: true, trigger: 'blur', validator: validatePassword2},
        email: {required: true, trigger: 'blur', validator: validateEmail},
        code: {required: true, trigger: 'blur', validator: validateCode},
      },
      addInfoShow: false,
      userInfo: {},// 登录成功后用户信息
      isPhone: isMobile(),
      // 注册用户
      regInfo: {
        dialog: false,
        info: {
          account: "",
          phone: "",
          password1: "",
          password2: "",
          email: "",
          emailCode: "",
          name: "",
          sex: "男",
          identity: "",
        },
        rules: {
          account: {required: true, trigger: 'blur', validator: (r, v, c) => validateRegAccount(r, v, c, 20, '账号')},
          password1: {required: true, trigger: 'blur', validator: validatePassword},
          password2: {required: true, trigger: 'blur', validator: validateRegPassword2},
          email: {required: true, trigger: 'blur', validator: validateRegEmail},
          emailCode: {required: true, trigger: 'blur', validator: validateCode},
          phone: {required: true, trigger: 'blur', validator: validateRegPhoneNumber},
          name: {required: true, trigger: 'blur', validator: (r, v, c) => validateName(r, v, c, 20, '姓名')},
          sex: {required: true, trigger: 'change', message: '请选择性别'},
          identity: {required: true, trigger: 'blur', validator: (r, v, c) => validateName(r, v, c, 10, '身份')},
        }
      }
    }
  },
  async created() {
    // 获取缓存的课程信息
    if (sessionStorage.getItem("courseInfo")) {
      let courseInfo = JSON.parse(sessionStorage.getItem("courseInfo"))
      // 保存课程信息到store
      await store.dispatch("setCourseInfo", courseInfo)
      await store.dispatch("setCourseId", courseInfo.courseId)
      if (sessionStorage.getItem("introduceId")) {
        await store.dispatch("setIntroduceId", sessionStorage.getItem("introduceId"))
      }
    }
  },
  async mounted() {
    let clientHeight = document.documentElement.clientHeight
    // 输入框自动获取焦点
    if (this.loginForm.account === '') {
      this.$refs.account.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {
    // 点击发送邮箱验证码按钮
    async clickSendEmailCodeBtn() {
      let email = this.addInfoForm.email;
      if (!email) {
        msg_err("请先输入您的邮箱地址")
        return
      }
      if (!regCheck("email", email)) {
        msg_err("邮箱格式错误")
        return;
      }
      if (await UserModel.sendBindEmail(this.addInfoForm.email)) {
        this.$set(this.addInfoForm, "send", true);
      }
    },
    // 登录框-账户输入改变
    async onLoginAccountChange(account) {

    },
    // 检测大小写
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    // 显示密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 处理登录
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          let resultData = await UserModel.loginInPlatform(this.loginForm.account, this.loginForm.password)
          if (resultData.token) {
            this.userInfo = resultData.userInfo
            // 正常登录
            if (this.userInfo.hasLogin === false) {// 重未登录
              this.openAddInfoDialog()
            } else {// 跳转到对应角色页面
              this.jumpToIndex()
            }
          }
        }
      })
    },
    // 打开完善信息框
    openAddInfoDialog() {
      this.addInfoForm = {
        password1: "",
        password2: "",
        email: ""
      }
      this.addInfoShow = true
      setTimeout(() => {
        this.$refs.addInfoForm.clearValidate()
      }, 300)
    },
    // 根据角色跳转
    jumpToIndex() {
      let coursePath = sessionStorage.getItem("coursePath")
      window.location.href = "/" + coursePath
    },
    // 点击补充信息确认按钮
    clickAddInfoBtn() {
      this.$refs.addInfoForm.validate(async valid => {
        if (valid) {
          if (await UserModel.supplementUserInfoOnFirstLogin(this.addInfoForm.password1, this.addInfoForm.email, this.addInfoForm.code)) {
            msg_success("完善信息成功")
            this.jumpToIndex()
          }
        } else {
          return false
        }
      })
    },
    // 点击忘记密码按钮
    clickForgetPasswordBtn() {
      this.findPasswordShow = true
      if (this.loginForm.account) {
        this.resetForm.account = this.loginForm.account
      } else {
        this.resetForm.account = ""
      }
      this.resetForm.email = ""
      setTimeout(() => {
        this.$refs["resetForm"].clearValidate();
      }, 300)
    },
    // 忘记密码框-账户输入改变
    async onResetAccountChange(account) {

    },
    // 点击重置密码按钮
    clickResetPasswordBtn() {
      this.$refs.resetForm.validate(async valid => {
        if (valid) {
          let result = await UserModel.forgetPasswordSendEmail(this.resetForm.account)
          if (result) {
            this.findPasswordShow = false;
          }
        } else {
          return false
        }
      })
    },
    // 注册功能集
    RegMethods() {
      let $this = this;
      return {
        // 点击校外人士注册按钮
        clickOpenRegBtn() {
          $this.regInfo.dialog = true
          $this.regInfo.info = {}
          setTimeout(() => {
            $this.$refs["regForm"].clearValidate();
          }, 500)
        },
        // 点击发送邮箱验证码按钮
        async clickSendEmailCodeBtn() {
          let email = $this.regInfo.info.email;
          if (!email) {
            msg_err("请先输入您的邮箱地址")
            return
          }
          if (!regCheck("email", email)) {
            msg_err("邮箱格式错误")
            return;
          }
          if (await UserModel.sendRegEmail($this.regInfo.info.email)) {
            $this.$set($this.regInfo, "send", true);
          }
        },
        // 点击注册按钮
        async clickRegBtn() {
          $this.$refs["regForm"].validate(async valid => {
            if (valid) {
              if (!$this.regInfo.send) {
                msg_err("请先发送邮箱验证码后再试！")
                return
              }
              let regResult = await UserModel.regAccount({
                account: $this.regInfo.info.account,
                name: $this.regInfo.info.name,
                password: $this.regInfo.info.password2,
                sex: $this.regInfo.info.sex,
                phone: $this.regInfo.info.phone,
                email: $this.regInfo.info.email,
                emailCode: $this.regInfo.info.emailCode,
                identity: $this.regInfo.info.identity
              })
              if (regResult) {
                if ($this.systemConfig.regUserEnable === "true") {
                  msg_success("注册成功,请登录！")
                  $this.regInfo.dialog = false
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                } else {
                  msg_success("注册成功,您的账号将在系统审核后启用！届时您将收到邮件通知，请注意查收！")
                  $this.regInfo.dialog = false
                }
              }
            } else {
              return false
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@bg: #f9f9f9;
@light_gray: #fff;
@cursor: #fff;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 90%;

    &:nth-child(1) {
      width: 100%;
    }

    input {
      background: @bg;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 10px 5px 10px 13px;
      color: #333;
      height: 47px;
      font-size: 14px;


      &:-webkit-autofill {
        box-shadow: inset 0px 60px 0px @bg;;
        -webkit-text-fill-color: #333 !important;
        color: #222;
        font-size: 14px;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: @bg;
    border-radius: 5px;
    color: #454545;
    margin-bottom: 20px;
  }
}
</style>

<style lang="less" scoped>
// 登录按钮框
.btn-box {
  position: relative;

  .more-btn {
    position: absolute;
    left: 0px;
    top: 5px;
    color: #fff;
  }

  .reg-btn {
    position: absolute;
    left: -20px;
    top: 5px;
    color: #fff;
  }

  .btn-forget {
    position: absolute;
    right: 0px;
    top: 5px;
    color: #fff;
  }
}

.bottomTips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  z-index: 0;

  div {
    margin-top: 3px;
  }
}

@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #fff;

.login-container {
  min-height: 100%;
  width: 100%;

  overflow: hidden;

  .login-form {
    position: relative;
    width: 550px;
    max-width: 100%;
    padding: 0px 45px;
    margin: 0 auto;
    z-index: 2;
  }

  .tips, .mobile-bottom-tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;


    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: @dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    padding-top: 160px;

    .title-img {
      text-align: center;

      img {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
    }

    .title {
      font-size: 30px;
      color: @light_gray;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }

    .sub-title {
      font-size: 26px;
      color: @light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.cb-slideshow {
  margin: 0px;
}

.cb-slideshow,
.cb-slideshow:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.cb-slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 1;
  z-index: 0;
  -webkit-backface-visibility: hidden;
}

.system-tips {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}

.teacher-tip {
  position: absolute;
  right: 30px;
  top: 5px;
  color: #777;
}
</style>
